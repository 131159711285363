<template>
	<div class="section-heading">{{ localCaptializeWords(section) }} {{ localCaptializeWords(list_name) }}</div>
	<div v-if="!editing" class="list-picker-list">
		<div>
			<div v-for="item in filterList('selected')" class="selected-list-picker-list">{{ item.description }}</div>
		</div>
        <div :class="['button', Number.isInteger(primary_id) ? 'button-dark' : 'disabled']"  @click="editing = true"><font-awesome-icon icon="edit" /></div>
	</div>

	<div v-if="editing">
		<div :class="['draggable-container', 'selected', { active: selectedActive }]" @drop="onDrop($event, 'selected')" @dragenter.prevent="toggleActive('selected')" @dragover.prevent @dragleave.prevent="toggleActive('selected')">
			<div v-for="(item, index) in filterList('selected')" :key="'selected_' + index" class="draggable-element" draggable="true" @dragstart="startDrag($event, item)" @dragend="stopDrag($event)">
				{{ item.description }}
			</div>
		</div>
		<div class="drag-instructions">
			<span><font-awesome-icon icon="arrow-up" /> Selected</span>
			<span>Drag to set {{ localCaptializeWords(list_name) }}</span>
			<span>Available <font-awesome-icon icon="arrow-down" /></span>
		</div>
		<div :class="['draggable-container', 'available', { active: availableActive }]" @drop="onDrop($event, 'available')" @dragenter.prevent="toggleActive('available')" @dragover.prevent @dragleave.prevent="toggleActive('available')">
			<div v-for="(item, index) in filterList('available')" :key="'available_' + index" class="draggable-element" draggable="true" @dragstart="startDrag($event, item)" @dragend="stopDrag($event)">
				{{ item.description }}
			</div>
		</div>

        <div class="detail-list-picker-detail-controls">
            <div class="button cancel" @click="cancelEditing()">Cancel</div>
            <div class="button" @click="saveSelectedList()"><LabelWithLoadingIcon :loading="loading" :label="'Save ' + list_name"/></div>
            <Message :message="message" :error="errorStatus" delay="3" :key="message" />
        </div>

	</div>

    <Message :message="message" :error="errorStatus" delay="3" :key="message" />

</template>
<script>
import Message from '@/components/Message.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import { mapActions } from 'vuex'
export default {
	name: 'detail-list-picker',
    props: {
		section: {
            type: String,
            default: ''
		},
		list_name: {
            type: String,
            default: ''
		},
        primary_id: {
            type: [Number, Boolean],
            default: false
        },
        disabled: {
            type: [String, Boolean],
            default: false
        }
    },
    components: {
        Message,
        LabelWithLoadingIcon
    },
	data() {
		return {
			primaryKey: '',
			list: [],
			editingList: [],
			editing: false,
            message: '',
            errorStatus: false,
            loading: false,
			selectedActive: false,
			availableActive: false
		}
	},
    created() {
		this.primaryKey = this.list_name.slice(0, -1) + '_id';
		if (this.primary_id) {
        	this.getList()
		}
	},
    computed: {
		newSelected() {
			return this.filterList('selected')
		}
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        localCaptializeWords(words) {
            return this.caplitalizeWords(words)
        },
        getList() {
			if (!Number.isInteger(this.primary_id)) { return }
    		this.apiCall({ destination: 'get_list_' + this.section + '_' + this.list_name, data: { primary_id: this.primary_id } }).then(obj => {
    			if (obj.status == 'success') {
		            // build the listCopy
		            this.list = []
		            obj.list.forEach(item => {
						let data = {}
						data[this.primaryKey] = item[this.primaryKey]
						data['description'] = item.description
						data['list'] = item[this.section + '_' + this.primaryKey] ? 'selected' : 'available'
		                this.list.push(data)
		            })
					this.editingList = JSON.parse(JSON.stringify(this.list));
    			} else {
					this.message = obj.message
				}
    		})
        },
		filterList(list) {
			return this.editingList.filter(item => item.list == list)
		},
		startEditing() {
			this.editingList = this.list
		},
		toggleActive(which) {
			if (which == 'selected') {
				this.selectedActive = !this.selectedActive
			} else {
				this.availableActive = !this.availableActive
			}
		},
		startDrag(event, item) {
			event.dataTransfer.dropEffect = 'move'
			event.dataTransfer.effectAllowed = 'move'
			event.dataTransfer.setData('itemId', item[this.primaryKey])
			event.target.classList.add('dragging');
		},
		stopDrag(event) {
			event.target.classList.remove('dragging');
		},
		onDrop(event, list) {
			this.selectedActive = false
			this.availableActive = false
			let itemId = event.dataTransfer.getData('itemId')
			let item = this.editingList.find(item => item[this.primaryKey] == itemId)
			item.list = list
		},
		cancelEditing() {
			this.editingList = JSON.parse(JSON.stringify(this.list));
			this.editing = false
		},
        saveSelectedList() {
			if (this.loading) { return }
			this.loading = true
    		this.apiCall({ destination: 'save_list_' + this.section + '_' + this.list_name, data: { primary_id: this.primary_id, list: this.editingList } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.list = JSON.parse(JSON.stringify(this.editingList));
					this.editing = false
					this.message = ''
    			} else {
					this.message = obj.message
				}
    		})
        }
	}
}
</script>
<style lang="scss" scoped>
.list-picker-list {
    display: grid;
    grid-template-columns: 1fr 2.5em;
    grid-column-gap: 2em;
    margin: 1em 0 0 0;
    input { font-size: 0.9em; }
    .button { font-size: 0.7em; height: 2.75em; }
}

.selected-list-picker-list {
	padding: 0.25em 0;
	border-top: 1px dotted #ccc;
}
.selected-list-picker-list:first-of-type {
	border-top: 0 none;
}
.drag-instructions {
	font-size: 0.8em;
	margin: 0.5em 0;
	text-align: center;
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
}
.draggable-container {
	min-height: 2em;
	padding: 0.5em;
	border: 1px solid #ccc;
	background-color: #f4f4f4;
    transition: 0.3s ease all;
	&.active {
    	background-color: #e4e4e4;
	}
	.draggable-element {
    	background-color: #fff;
		padding: 0.25em;
		border: 1px solid #ccc;
		cursor: grab;
	}
	.dragging {
		background-color: #e4e4e4;
		cursor: grabbing;
	}
}
.detail-list-picker-detail-controls {
	margin: 0.75em 0 0 0;
    .button { margin: 0 1em 0 0;}
}
</style>
